<template>
  <div>
    <b-modal
      ref="chi-tiet-loi-nhuan"
      ok-only
      ok-title="Đóng lại"
      centered
      size="xl"
      :title="titleModal"
    >
      <b-table
        :items="tableDataChiTietLoiNhuan"
        :fields="fieldsChiTietLoiNhuan"
        responsive="sm"
        stacked="md"
      >
        <template #thead-top="data">
          <b-tr>
            <b-th colspan="2" />
            <b-th
              colspan="3"
              variant="secondary"
              class="text-center"
            >
              <span>ĐẦU TƯ {{ data.index }}</span>
            </b-th>
            <b-th
              colspan="3"
              variant="primary"
              class="text-center"
            >
              <span>DOANH SỐ</span>
            </b-th>
            <b-th
              colspan="2"
            />
          </b-tr>
        </template>
        <template #cell(gia_von)="data">
          <div class="text-right">
            {{ data.item.gia_von != null ? Number( data.item.gia_von).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
        <template #cell(khuyen_mai)="data">
          <div class="text-right">
            {{ data.item.khuyen_mai != null ? Number( data.item.khuyen_mai).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
        <template #cell(so_tien)="data">
          <div class="text-right">
            {{ data.item.so_tien != null ? Number( data.item.so_tien).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
        <template #cell(gia_ban)="data">
          <div class="text-right">
            {{ data.item.gia_ban != null ? Number( data.item.gia_ban).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
        <template #cell(khuyen_mai_gia_ban)="data">
          <div class="text-right">
            {{ data.item.khuyen_mai_gia_ban != null ? Number( data.item.khuyen_mai_gia_ban).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
        <template #cell(so_tien_thanh_toan)="data">
          <div class="text-right">
            {{ data.item.so_tien_thanh_toan != null ? Number( data.item.so_tien_thanh_toan).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
        <template #cell(loi_nhuan)="data">
          <div class="text-right">
            {{ data.item.loi_nhuan != null ? Number( data.item.loi_nhuan ).toLocaleString('vi', {minimumFractionDigits: 0}) : '0' }}
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import { getUserData } from '@/auth/utils'
import {
  BTable, BTh, BTr,
} from 'bootstrap-vue'

export default {
  components: {
    BTh,
    BTr,
    BTable,
  },
  props: {
    daiLy: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      titleModal: 'Chi tiết lợi nhuận',
      tableDataChiTietLoiNhuan: [],
      fieldsChiTietLoiNhuan: [
        { key: 'ngay', label: 'Ngày' },
        { key: 'khach_hang', label: 'Khách hàng' },
        { key: 'gia_von', label: 'Giá vốn' },
        { key: 'khuyen_mai', label: 'Khuyến mại' },
        { key: 'so_tien', label: 'Số tiền' },
        { key: 'gia_ban', label: 'Giá bán' },
        { key: 'khuyen_mai_gia_ban', label: 'Khuyến mại' },
        { key: 'so_tien_thanh_toan', label: 'Số tiền' },
        { key: 'loi_nhuan', label: 'Lợi nhuận' },
      ],
    }
  },
  mounted() {
    this.$root.$on('xemChiTietLoiNhuan', obj => {
      store.state.showBlock = true
      const currentUser = getUserData()

      Object.assign(obj, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        dai_ly: this.daiLy,
      })
      store.dispatch('app-user/xemChiTietLoiNhuan', obj)
        .then(response => {
          this.$refs['chi-tiet-loi-nhuan'].show()
          this.tableDataChiTietLoiNhuan = response.data.chiTietLoiNhuan
          this.titleModal = response.data.title
          store.state.showBlock = false
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            this.showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            this.showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            this.showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    })
  },
}
</script>

<style scoped>

</style>
