<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>

    <b-card
      no-body
      m-0
    >
      <b-card-header>
        <b-card-title>Thống kê lượt xem</b-card-title>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                cols="12"
                md="11"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <!-- Đại lý -->
                    <validation-provider
                      #default="validationContext"
                      name="Đại lý"
                      rules="required"
                    >
                      <b-form-group
                        label="Đại lý"
                        label-for="dai-ly-thong-ke"
                      >
                        <v-select
                          v-model="FormThongKeDaiLyData.dai_ly"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="optionsDaiLy"
                          :clearable="false"
                          input-id="dai-ly-thong-ke"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <!-- Nhóm -->
                    <validation-provider
                      #default="validationContext"
                      name="Nhóm"
                      rules="required"
                    >
                      <b-form-group
                        label="Nhóm"
                        label-for="loai-thong-ke"
                      >
                        <v-select
                          v-model="FormThongKeDaiLyData.loai_thong_ke"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="loaiThongKe"
                          :clearable="false"
                          input-id="loai-thong-ke"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                    :class="FormThongKeDaiLyData.loai_thong_ke.key === 'Theo ngày'? '' : 'hidden'"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Từ ngày"
                        >
                          <b-form-group
                            label="Từ ngày"
                            label-for="tu-ngay"
                          >
                            <flat-pickr
                              id="tu-ngay"
                              v-model="FormThongKeDaiLyData.tu_ngay"
                              class="form-control"
                              :config="configDate"
                              placeholder="Chọn ngày"
                              :state="getValidationState(validationContext)"
                              trim
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <validation-provider
                          #default="validationContext"
                          name="Đến ngày"
                        >
                          <b-form-group
                            label="Đến ngày"
                            label-for="den-ngay"
                          >
                            <flat-pickr
                              id="den-ngay"
                              v-model="FormThongKeDaiLyData.den_ngay"
                              class="form-control"
                              :config="configDate"
                              placeholder="Chọn ngày"
                              :state="getValidationState(validationContext)"
                              trim
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    md="1"
                    :class="FormThongKeDaiLyData.loai_thong_ke.key === 'Theo ngày'? '' : 'hidden'"
                  >
                    <div class="mt-2">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="success"
                        class="btn-icon rounded-circle"
                        type="submit"
                      >
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="11"
                :class="FormThongKeDaiLyData.loai_thong_ke.key === 'Theo ngày'? 'hidden' : ''"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Từ tháng"
                    >
                      <b-form-group
                        label="Từ tháng"
                        label-for="tu-thang"
                      >
                        <v-select
                          v-model="FormThongKeDaiLyData.tu_thang"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="thang"
                          :clearable="true"
                          input-id="tu-thang"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Từ năm"
                    >
                      <b-form-group
                        label="Từ năm"
                        label-for="tu-nam"
                      >
                        <b-form-input
                          id="tu-nam"
                          v-model="FormThongKeDaiLyData.tu_nam"
                          type="number"
                          placeholder="2021"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Đến tháng"
                    >
                      <b-form-group
                        label="Đến tháng"
                        label-for="den-thang"
                      >
                        <v-select
                          v-model="FormThongKeDaiLyData.den_thang"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="thang"
                          :clearable="true"
                          input-id="den-thang"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="đến năm"
                    >
                      <b-form-group
                        label="Đến năm"
                        label-for="den-nam"
                      >
                        <b-form-input
                          id="den-nam"
                          v-model="FormThongKeDaiLyData.den_nam"
                          type="number"
                          placeholder="2021"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                cols="12"
                md="1"
                :class="FormThongKeDaiLyData.loai_thong_ke.key === 'Theo ngày'? 'hidden' : ''"
              >
                <div class="mt-2">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="success"
                    class="btn-icon rounded-circle"
                    type="submit"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </div>

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <h4>MUA GÓI: <span class="text-success">+ {{ Number( this.tongSoBaiDaMua).toLocaleString('vi', {minimumFractionDigits: 0}) }}</span> (bài)</h4>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <h4>BÁN GÓI: <span class="text-danger">- {{ Number( this.tongSoBaiDaBan).toLocaleString('vi', {minimumFractionDigits: 0}) }}</span> (bài)</h4>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <h4>XUẤT BÀI: <span class="text-danger">- {{ Number( this.tongSoBaiDaXem).toLocaleString('vi', {minimumFractionDigits: 0}) }}</span> (bài)</h4>
          </b-col>

        </b-row>
        <b-table
          ref="refDoanhSoTable"
          :items="tableData"
          empty-text="Không có dữ liệu"
          show-empty
          primary-key="ngay"
          :fields="fieldColumns"
          responsive="sm"
          stacked="md"
        >
          <template #cell(xem_bai)="data">
            <div class="text-right">
              <b-badge
                variant="danger"
                class="badge-glow"
              >
                - {{ Number( data.item.xem_bai ).toLocaleString('vi', {minimumFractionDigits: 0}) }}
              </b-badge>
            </div>
          </template>
          <template #cell(ban_goi)="data">
            <div class="text-right">
              <b-badge
                variant="danger"
                class="badge-glow"
              >
                - {{ Number( data.item.ban_goi ).toLocaleString('vi', {minimumFractionDigits: 0}) }}
              </b-badge>
            </div>
          </template>
          <template #cell(mua_bai)="data">
            <div class="text-right">

              <b-badge
                variant="success"
                class="badge-glow"
              >
                + {{ Number( data.item.mua_bai ).toLocaleString('vi', {minimumFractionDigits: 0}) }}
              </b-badge>
            </div>
          </template>

        </b-table>
      </b-card-body>
    </b-card>

    <chi-tiet-loi-nhuan />

  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BTable,
  BBadge,
} from 'bootstrap-vue'

import ChiTietLoiNhuan from '@/views/users/dai-ly/view/thong-ke/ChiTietLoiNhuan.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import userStoreModule from '@/views/users/userStoreModule'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBadge,
    ChiTietLoiNhuan,
    BFormGroup,
    BButton,
    BCardBody,
    BFormInvalidFeedback,
    BFormInput,
    BForm,
    flatPickr,
    BRow,
    BCol,
    BTable,
    BCardTitle,
    BCardHeader,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCard,
  },
  data() {
    return {
      required,
      tableData: [],
      tongSoBaiDaXem: 0,
      tongSoBaiDaMua: 0,
      tongSoBaiDaBan: 0,
      configDate: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
      },
      loaiThongKe: [
        { key: 'Theo ngày', label: 'Theo ngày' },
        { key: 'Theo tháng', label: 'Theo tháng' },
      ],
      thongKeNgay: false,
      thang: [
        { key: 1, label: 'Tháng 1' },
        { key: 2, label: 'Tháng 2' },
        { key: 3, label: 'Tháng 3' },
        { key: 4, label: 'Tháng 4' },
        { key: 5, label: 'Tháng 5' },
        { key: 6, label: 'Tháng 6' },
        { key: 7, label: 'Tháng 7' },
        { key: 8, label: 'Tháng 8' },
        { key: 9, label: 'Tháng 9' },
        { key: 10, label: 'Tháng 10' },
        { key: 11, label: 'Tháng 11' },
        { key: 12, label: 'Tháng 12' },
      ],
      fieldColumns: [
        { key: 'stt', label: 'STT' },
        { key: 'dai_ly', label: 'Đại lý' },

        { key: 'ngay', label: 'Ngày / Tháng - Năm' },
        { key: 'ban_goi', label: 'Bán gói' },
        { key: 'xem_bai', label: 'Xuất bài' },
        { key: 'mua_bai', label: 'Mua gói' },
      ],
    }
  },
  methods: {
    onSubmit() {
      let choPhepThongKe = false
      const currentUser = getUserData()
      if (this.FormThongKeDaiLyData.loai_thong_ke.key === 'Theo tháng') {
        if (this.FormThongKeDaiLyData.tu_thang === '' || this.FormThongKeDaiLyData.den_thang === '' || this.FormThongKeDaiLyData.tu_nam === '' || this.FormThongKeDaiLyData.den_nam === '') {
          this.$swal({
            title: 'Thông báo!',
            text: ' Vui lòng nhập đầy đủ thông tin thống kê từ tháng - năm đến tháng - năm!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
            buttonsStyling: false,
          })
        } else choPhepThongKe = true
      } else if (this.FormThongKeDaiLyData.tu_ngay === '' || this.FormThongKeDaiLyData.den_ngay === '') {
        this.$swal({
          title: 'Thông báo',
          text: ' Vui lòng nhập đầy đủ thông tin thống kê từ ngày bắt đầu đến ngày kết thúc',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
          buttonsStyling: false,
        })
      } else choPhepThongKe = true

      if (choPhepThongKe) {
        store.state.showBlock = true
        Object.assign(this.FormThongKeDaiLyData, {
          auth: currentUser.auth_key,
          uid: currentUser.id,
          thong_ke: true,
        })
        store.dispatch('app-user/thongKe', this.FormThongKeDaiLyData)
          .then(response => {
            this.tableData = response.data.ket_qua
            this.tongSoBaiDaXem = response.data.tongSoBaiDaXem
            this.tongSoBaiDaMua = response.data.tongSoBaiDaMua
            this.tongSoBaiDaBan = response.data.tongSoBaiDaBan
            this.$refs.refDoanhSoTable.refresh()
            store.state.showBlock = false
          })
          .catch(e => {
            if (typeof e.response !== 'undefined') {
              this.showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
            } else if (typeof e.message !== 'undefined') {
              this.showToast('danger', 'Thông báo', 'BellIcon', e.message)
            } else {
              this.showToast('danger', 'Thông báo', 'BellIcon', e)
            }
            store.state.showBlock = false
          })
      }
    },
    xemChiTietLoiNhuan(obj) {
      this.$root.$emit('xemChiTietLoiNhuan', obj)
    },
  },
  setup() {
    const blankFormThongKeDaiLyData = {
      loai_thong_ke: { key: 'Theo tháng', label: 'Theo tháng' },
      dai_ly: null,
      tu_ngay: '',
      den_ngay: '',
      tu_thang: '',
      tu_nam: '',
      den_thang: '',
      den_nam: '',
    }

    const optionsDaiLy = ref([])
    const FormThongKeDaiLyData = ref(JSON.parse(JSON.stringify(blankFormThongKeDaiLyData)))

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    const resetuserData = () => {
      FormThongKeDaiLyData.value = JSON.parse(JSON.stringify(blankFormThongKeDaiLyData))
    }

    const currentUser = getUserData()
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.state.showBlock = true
    store
      .dispatch('app-user/fetchAllDaiLy', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
        thong_ke: true,
      })
      .then(response => {
        optionsDaiLy.value = response.data
        const [firstItem] = response.data
        FormThongKeDaiLyData.value.dai_ly = firstItem
        store.state.showBlock = false
      })
      .catch(() => {
        store.state.showBlock = false
      })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      optionsDaiLy,
      FormThongKeDaiLyData,
      resetuserData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
