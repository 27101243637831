import axios from '@axios'
import config from '../../config.json'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    taiFileExcel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/tai-file-excel`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    muaBai(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/mua-bai`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    xemBai(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/xem-bai`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    thongKe(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}thong-ke/thong-ke-doanh-so-dai-ly`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    taiFile(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/tai-file`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    taiFileTiLeCacConSo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/tai-file-ti-le-cac-con-so`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    xemChiTietLoiNhuan(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}thong-ke/xem-chi-tiet-loi-nhuan`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/get-data`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    xemChiTiet(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/xem-chi-tiet`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllKhuVuc(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}danh-muc/get-all-khu-vuc`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllDaiLy(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/get-all-dai-ly`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllDaiLyList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/get-all-dai-ly-list`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListVaiTro(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}vai-tro/get-all-vai-tro`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addKhachHang(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/add-khach-hang`, userData, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteKhachHang(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/delete`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getKhachHang(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/load`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadThongTinThanhToan(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/load-thong-tin-thanh-toan`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    thanhToan(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/khach-hang-thanh-toan`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    napTien(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}giao-dich/dai-ly-nap-tien`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    nhanMaGiaoDich(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}giao-dich/nhan-ma-giao-dich`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getTree(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/get-tree`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    loadProfile(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/load-profile`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfile(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}user/update-profile`, params, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
